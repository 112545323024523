import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import SEO from "../components/seo"
import StaticTextContainer from "../components/StaticTextContainer"

const ShopInfoPage = ({ data }) => {
  const { title, body } = data?.allShopifyShopPolicy?.edges[0]?.node
  return (
    <>
      <SEO path="/refund-policy" title="Jessica Coppet - Refund Policy" />
      <StaticTextContainer title={title} body={body} />
    </>
  )
}

ShopInfoPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query GetRefundPolicy {
    allShopifyShopPolicy(filter: { handle: { eq: "refund-policy" } }) {
      edges {
        node {
          title
          body
        }
      }
    }
  }
`

export default ShopInfoPage
