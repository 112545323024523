import React from "react"
import PropTypes from "prop-types"

import "./StaticTextContainer.scss"

const StaticTextContainer = ({ title, body }) => {
  return (
    <section className="static-text-container">
      <div className="static-text-container__container">
        <div className="static-text-container__title">{title}</div>
        <div
          className="static-text-container__body"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </section>
  )
}

StaticTextContainer.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default StaticTextContainer
